import { useRouteError } from "react-router-dom";

interface ErrorReturn {
    statusText: string,
    message: string
}

const ErrorPage = () => {
  const error = useRouteError();
  const actualError = error as ErrorReturn;

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{actualError.statusText || actualError.message}</i>
      </p>
    </div>
  );
}

export default ErrorPage;