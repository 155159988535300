import * as React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { styled } from '@mui/material'
import { ProgressiveImage } from '../components'

const MainGallery = styled('main')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'white',
    textAlign: 'center',
    marginLeft: '40px',
    marginRight: '40px',
    marginBottom: '100px',
    marginTop: '0',
}))

const itemData = [
    {
        img: './Gallery/1',
        title: 'Cutting Board',
    },
    {
        img: './Gallery/2',
        title: 'Adirondack Chairs',
    },
    {
        img: './Gallery/3',
        title: 'Cedar Bench',
    },
    {
        img: './Gallery/4',
        title: 'Cedar Bins',
    },
    {
        img: './Gallery/5',
        title: 'Kitchen Bench',
    },
    {
        img: './Gallery/6',
        title: 'Large and Small Adirondack Chairs',
    },
    {
        img: './Gallery/7',
        title: 'Child Size Adirondack Chair',
    },
    {
        img: './Gallery/8',
        title: 'Cedar Bins on Show Table',
    },
    {
        img: './Gallery/9',
        title: 'Multiple Cutting Boards Shown Together',
    },
    {
        img: './Gallery/10',
        title: 'Large Maple Cutting Board',
    },
    {
        img: './Gallery/11',
        title: 'Two Cedar Mug Racks',
    },
    {
        img: './Gallery/12',
        title: 'Birdhouse',
    },
    {
        img: './Gallery/13',
        title: 'Apex Style Mug Rack',
    },
    {
        img: './Gallery/14',
        title: 'Multiple Size Cutting Boards Top View',
    },
    {
        img: './Gallery/15',
        title: 'Wood Stool',
    },
    {
        img: './Gallery/16',
        title: 'Blanket Ladder',
    },
    {
        img: './Gallery/17',
        title: 'Beachhouse Table',
    },
    {
        img: './Gallery/18',
        title: 'Liveedge Cedar Bench Outdoors',
    },
    {
        img: './Gallery/19',
        title: 'Large Thick Cutting Board',
    },
    {
        img: './Gallery/20',
        title: 'Adirondack Chairs',
    },
]

const GalleryPage = () => {
    return (
        <MainGallery>
            <h1 style={{ fontFamily: "'Cinzel', serif" }}>Gallery</h1>
            <div style={{ margin: '0 auto', padding: '10px' }}>
                <ImageList
                    variant="masonry"
                    cols={2}
                    gap={1}
                    style={{ maxWidth: '800px' }}
                >
                    {itemData.map((item) => (
                        <a
                            href={`/${item.img}.jpg`}
                            target="_blank"
                            rel="noreferrer"
                            key={item.img}
                            style={{
                                margin: '0 auto',
                                color: 'white',
                            }}
                        >
                            <ImageListItem key={item.img}>
                                <ProgressiveImage
                                    lowRes={`${item.img}_LOW.jpg`}
                                    highRes={`${item.img}.jpg`}
                                    alt={item.title}
                                />
                            </ImageListItem>
                        </a>
                    ))}
                </ImageList>
            </div>
        </MainGallery>
    )
}

export default GalleryPage
