import { styled, Typography } from '@mui/material'

const AboutMain = styled('main')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'white',
    textAlign: 'center',
}))

const ImageContainer = styled('section')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
}))

const CardContainer = styled('section')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '40px',
}))

const TypographyContent = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    fontSize: '18px',
    fontFamily: "'Sorts Mill Goudy', serif",
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '20px',
    border: 'solid white 1px',
    maxWidth: '800px',
    width: '85%',
}))

const AboutLink = styled('a')(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '14px',
    fontFamily: "'Sorts Mill Goudy', serif",
    letterSpacing: '.1rem',
    textAlign: 'center',
    margin: '0',
    overflowWrap: 'break-word',
    textDecoration: 'none',
}))

const AboutPage = () => {
    return (
        <AboutMain>
            <h1 style={{ fontFamily: "'Cinzel', serif" }}>About Us</h1>
            <ImageContainer>
                <img
                    src="./AboutPage.jpeg"
                    alt="andrew at an event"
                    style={{
                        width: '90%',
                        maxWidth: '800px',
                        // maxHeight: '400px',
                    }}
                />
            </ImageContainer>
            <CardContainer>
                <TypographyContent variant="body2">
                    {`Hello! My name is Andrew Evans - I love woodworking &
                        building things. This site is intended to showcase some
                        of my work and my typical inventory. My wife & I
                        regularly attend Richmond-area Farmers Markets to
                        showcase my work. You can follow me on `}
                    <AboutLink href="https://www.instagram.com/andrewevans0102/?hl=en">
                        Instagram
                    </AboutLink>
                    {` for regular updates. If you'd like to learn more
                        about something I've made feel free to reach out to me
                        at `}
                    <AboutLink href="mailto:AndrewEvansWoodworking@gmail.com">
                        AndrewEvansWoodworking@gmail.com
                    </AboutLink>
                </TypographyContent>
            </CardContainer>
        </AboutMain>
    )
}

export default AboutPage
