import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'

const pages = ['about', 'catalog', 'gallery']

function NavBar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    )

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    return (
        <AppBar position="static" style={{ backgroundColor: 'black' }}>
            <Container maxWidth="xl" style={{ backgroundColor: 'black' }}>
                <Toolbar disableGutters style={{ backgroundColor: 'black' }}>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Link to="/">
                            <img
                                src="./HomeButton.jpg"
                                alt="desktop view home button"
                                style={{
                                    maxWidth: '40px',
                                    maxHeight: '40px',
                                    borderRadius: '50%',
                                    marginRight: '20px',
                                }}
                            />
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Link
                                        to={`/${page}`}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            textAlign="center"
                                            style={{
                                                fontFamily: "'Cinzel', serif",
                                                color: '#013220',
                                                fontWeight: '800',
                                                letterSpacing: '.1rem',
                                                fontSize: '18px',
                                            }}
                                        >
                                            {page}
                                        </Typography>
                                    </Link>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <Link to="/">
                            <img
                                src="./HomeButton.jpg"
                                alt="mobile view home button"
                                style={{
                                    maxWidth: '40px',
                                    maxHeight: '40px',
                                    borderRadius: '50%',
                                    marginRight: '20px',
                                }}
                            />
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        {pages.map((page) => (
                            <Link
                                to={`/${page}`}
                                style={{ textDecoration: 'none' }}
                                key={page}
                            >
                                <Button
                                    onClick={handleCloseNavMenu}
                                    // sx={{ my: 2, color: 'white', display: 'block' }}
                                    style={{
                                        fontFamily: "'Cinzel', serif",
                                        color: 'white',
                                        fontWeight: '800',
                                        letterSpacing: '.1rem',
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                    }}
                                >
                                    {page}
                                </Button>
                            </Link>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
export default NavBar
