import {
    ImageList,
    ImageListItem,
    ImageListItemBar,
    styled,
    Typography,
} from '@mui/material'
import { ProgressiveImage } from '../components'

const CatalogMain = styled('main')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'white',
    textAlign: 'center',
}))

const CatalogContent = styled('section')(({ theme }) => ({
    margin: '0 auto',
    maxWidth: '800px',
}))

const TypographyContent = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    fontSize: '18px',
    fontFamily: "'Sorts Mill Goudy', serif",
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '20px',
    border: 'solid white 1px',
}))

const itemData = [
    {
        img: './Catalog/AdirondackChairs',
        title: 'Adirondack Chairs',
    },
    {
        img: './Catalog/BirdHouse',
        title: 'Birdhouse',
    },
    {
        img: './Catalog/BlanketLadder',
        title: 'Blanket Ladder',
    },
    {
        img: './Catalog/Boxes',
        title: 'Boxes',
    },
    {
        img: './Catalog/CedarBins',
        title: 'Bins',
    },
    {
        img: './Catalog/Coasters',
        title: 'Coasters',
    },
    {
        img: './Catalog/CuttingBoards',
        title: 'Cutting Boards',
    },
    {
        img: './Catalog/MugRacks',
        title: 'Mug Racks',
    },
]

const CatalogPage = () => {
    return (
        <CatalogMain>
            <h1 style={{ fontFamily: "'Cinzel', serif" }}>Catalog</h1>
            <CatalogContent>
                <div style={{ margin: '0 auto', padding: '10px' }}>
                    <TypographyContent>
                        I build different items throughout the year, but here is
                        a general list of what I typically have at the Farmers
                        Markets. Please reach out for specific inquiries.
                    </TypographyContent>
                </div>
                <div style={{ margin: '0 auto', padding: '10px' }}>
                    <ImageList
                        style={{ width: '90%', margin: '0 auto' }}
                        cols={1}
                        gap={8}
                    >
                        {itemData.map((item) => (
                            <>
                                <ImageListItem
                                    key={item.img}
                                    style={{ padding: '10px' }}
                                >
                                    <a
                                        href={`/${item.img}.jpg`}
                                        target="_blank"
                                        rel="noreferrer"
                                        key={item.img}
                                        style={{
                                            margin: '0 auto',
                                            color: 'white',
                                        }}
                                    >
                                        <ProgressiveImage
                                            lowRes={`${item.img}_LOW.jpg`}
                                            highRes={`${item.img}.jpg`}
                                            alt={item.title}
                                        />
                                    </a>
                                    <ImageListItemBar
                                        title={
                                            <span
                                                style={{
                                                    fontFamily:
                                                        "'Sorts Mill Goudy', serif",
                                                    fontSize: '24px',
                                                }}
                                            >
                                                {item.title}
                                            </span>
                                        }
                                        position="below"
                                    />
                                </ImageListItem>
                            </>
                        ))}
                    </ImageList>
                </div>
            </CatalogContent>
        </CatalogMain>
    )
}

export default CatalogPage
