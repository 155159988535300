import { useEffect, useState } from 'react'

interface ProgressiveImageProps {
    lowRes: string
    highRes: string
    alt: string
}

interface StyleProps {
    filter?: string
    clipPath?: string
    maxWidth?: string
    border?: string
}

export default function PregressiveImage(
    progressiveImageProps: ProgressiveImageProps
) {
    const [usedSrc, setUsedSrc] = useState(progressiveImageProps.lowRes)
    const [usedEffectStyle, setUsedEffectStyle] = useState<StyleProps>({
        filter: 'blur(5px)',
        clipPath: 'inset(0)',
        maxWidth: '90%',
        border: 'solid 5px',
    })

    useEffect(() => {
        const img = new Image()
        img.src = progressiveImageProps.highRes
        img.onload = () => {
            setUsedSrc(img.src)
            setUsedEffectStyle({ maxWidth: '90%', border: 'solid 5px' })
        }
    }, [progressiveImageProps.highRes])

    return (
        <img
            src={usedSrc}
            alt={progressiveImageProps.alt}
            style={{ transition: 'filter 0.1s ease-out', ...usedEffectStyle }}
        />
    )
}
