import { Box, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'

const MainPage = styled('main')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}))

const HomeImage = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
}))

const HomeLink = styled('a')(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '14px',
    fontFamily: "'Cinzel', serif",
    letterSpacing: '.1rem',
    textAlign: 'center',
    margin: '0',
}))

const ContactWrapper = styled('section')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
}))

const ContactSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderBottom: `solid ${theme.palette.secondary.main} 1px`,
    paddingBottom: '10px',
    maxWidth: '500px',
}))

const ContactLinks = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
}))

const RootPage = () => {
    return (
        <MainPage>
            <HomeImage>
                <img
                    src="./HomePageFullLogo.jpg"
                    alt="home page full logo"
                    style={{
                        width: '90%',
                        maxWidth: '400px',
                        maxHeight: '400px',
                    }}
                />
            </HomeImage>
            <HomeImage>
                <img
                    src="./HomePage2.png"
                    alt="home page andrew profile"
                    style={{
                        maxWidth: '400px',
                        maxHeight: '200px',
                        borderRadius: '50%',
                        margin: '40px',
                    }}
                />
            </HomeImage>
            <ContactWrapper>
                <ContactSection>
                    <p
                        style={{
                            textAlign: 'center',
                            color: 'white',
                            margin: '0',
                            fontSize: '18px',
                            fontFamily: "'Cinzel', serif",
                        }}
                    >
                        Contact me at
                    </p>
                    <HomeLink href="mailto:AndrewEvansWoodworking@gmail.com">
                        AndrewEvansWoodworking@gmail.com
                    </HomeLink>
                    <ContactLinks>
                        <a
                            href="https://www.instagram.com/andrewevans0102/?hl=en"
                            style={{ color: '#ADD8E6' }}
                        >
                            <FontAwesomeIcon
                                icon={faInstagram}
                                size="3x"
                                style={{ margin: '10px', color: '#ADD8E6' }}
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/profile.php?id=100078111621179"
                            style={{ color: '#ADD8E6' }}
                        >
                            <FontAwesomeIcon
                                icon={faFacebook}
                                size="3x"
                                style={{ margin: '10px', color: '#ADD8E6' }}
                            />
                        </a>
                    </ContactLinks>
                </ContactSection>
            </ContactWrapper>
        </MainPage>
    )
}

export default RootPage
