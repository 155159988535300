import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import { AboutPage, ErrorPage, GalleryPage, RootPage } from './routes'
import { NavBar } from './components'
import { createTheme, ThemeProvider } from '@mui/material'
import CatalogPage from './routes/CatalogPage'

const AEWTheme = createTheme({
    palette: {
        primary: {
            main: '#002416',
            light: '#af9cef',
        },
        secondary: {
            main: '#fada5e',
        },
    },
})

const Layout = () => {
    return (
        <>
            <NavBar />
            <Outlet />
        </>
    )
}

const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <RootPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/about',
                element: <AboutPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/catalog',
                element: <CatalogPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/gallery',
                element: <GalleryPage />,
                errorElement: <ErrorPage />,
            },
        ],
    },
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <ThemeProvider theme={AEWTheme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
